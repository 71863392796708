import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ReactGA from "react-ga4";
import { useCurrentBreakpoint } from "./useCurrentBreakpoint";
import { AppContentWithHeader } from "./AppContentWithHeader";

export const Lander: React.FC = () => {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Landing page",
    });

    const theme = useTheme();
    const currentBreakpoint = useCurrentBreakpoint();

    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerDimensions({
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const aspectRatio = 640 / 340;
    const breakpointWidth = theme.breakpoints.values[currentBreakpoint];
    const maxWidth = containerDimensions.width * 0.9;
    const maxHeight = containerDimensions.height * 0.7;

    let videoWidth = breakpointWidth !== 0 ? Math.min(maxWidth, breakpointWidth * 0.9) : maxWidth;
    let videoHeight = videoWidth / aspectRatio;

    const remainingHeight = containerDimensions.height - videoHeight;

    if (remainingHeight < 300) {
        videoHeight = videoHeight - (300 - remainingHeight);
        videoWidth = videoHeight * aspectRatio;
    }

    if (videoHeight > maxHeight) {
        videoHeight = maxHeight;
        videoWidth = videoHeight * aspectRatio;
    }

    // Ensure width is not more than 90% of container width
    if (videoWidth > maxWidth) {
        videoWidth = maxWidth;
        videoHeight = videoWidth / aspectRatio;
    }

    return (
        <AppContentWithHeader>
            <Box
                ref={containerRef}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textAlign: "center",
                    mx: 2,
                    borderRadius: 2,
                    bgcolor: "white",
                    minHeight: "500px",
                    overflowY: "hidden",
                    flexGrow: 1,
                    overflowX: "hidden",
                    mb: 3,
                }}
            >
                <Box
                    maxWidth="lg"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        justifyContent: "space-between",
                        flexGrow: 1,
                        // pt: currentBreakpoint === "xs" ? 8 : 6,
                        [theme.breakpoints.only("xxxs")]: {
                            pt: 6,
                        },
                        [theme.breakpoints.only("xxs")]: {
                            pt: 10,
                        },
                        [theme.breakpoints.only("xs")]: {
                            pt: 14,
                        },
                        [theme.breakpoints.only("sm")]: {
                            pt: 10,
                        },
                        // rest
                        [theme.breakpoints.up("md")]: {
                            pt: 6,
                        },
                    }}
                >
                    <TopMainContent />
                    <VideoContainer width={videoWidth} height={videoHeight} />
                </Box>
            </Box>
        </AppContentWithHeader>
    );
};

const VideoContainer: React.FC<{ width: number; height: number }> = ({ width, height }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                pb: 0,
                position: "relative",
                width: width,
                height: height,
                mx: "auto",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "70%",
                    height: "100%",
                    bgcolor: "secondary.main",
                    zIndex: 1,
                    borderRadius: 3,
                    [theme.breakpoints.only("xxxs")]: { bottom: 5 },
                    [theme.breakpoints.only("xxs")]: { bottom: 8 },
                    [theme.breakpoints.only("xs")]: { bottom: 10 },
                    [theme.breakpoints.up("sm")]: { bottom: 15 },
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "80%",
                    height: "100%",
                    bgcolor: "#FBBC45",
                    zIndex: 2,
                    borderRadius: 3,
                    border: 2,
                    borderColor: "white",
                    [theme.breakpoints.only("xxxs")]: { bottom: 2 },
                    [theme.breakpoints.only("xxs")]: { bottom: 4 },
                    [theme.breakpoints.only("xs")]: { bottom: 6 },
                    [theme.breakpoints.up("sm")]: { bottom: 0 },
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: 2,
                    borderColor: "primary.main",
                    backgroundColor: "white",
                    borderRadius: 3,
                    zIndex: 3,
                    overflow: "hidden",
                    [theme.breakpoints.only("xxxs")]: { bottom: -5 },
                    [theme.breakpoints.only("xxs")]: { bottom: -8 },
                    [theme.breakpoints.only("xs")]: { bottom: -10 },
                    [theme.breakpoints.up("sm")]: { bottom: -15 },
                }}
            >
                <iframe
                    id="video-iframe"
                    data-ph-capture-attribute-iframe="video-iframe"
                    src="https://www.loom.com/embed/fe4e40fa000b4406910a9ce247079138?sid=9382c4c2-c6c5-4868-9aef-f9f9b636ef37?sid=b074217b-5d3f-4103-b2c7-7297b6ff18b5?hide_share=true&hideEmbedTopBar=true&hide_title=true&hide_owner=true"
                    frameBorder="0"
                    allowFullScreen
                    style={{ width: "100%", height: "100%" }}
                />
            </Box>
        </Box>
    );
};

const TopMainContent: React.FC = () => {
    return (
        <Box
            sx={theme => ({
                mx: 1,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                // rowGap: breakpoint === "xxxs" ? 0 : 3,
                overflowY: "hidden",
                overflowX: "hidden",
                [theme.breakpoints.only("xxxs")]: {
                    rowGap: 0,
                },
                [theme.breakpoints.only("xxs")]: {
                    rowGap: 2,
                },
                [theme.breakpoints.up("xs")]: {
                    rowGap: 4,
                },
            })}
        >
            <Box
                sx={theme => ({
                    display: "flex",
                    flexDirection: "column",
                    px: 6,
                    [theme.breakpoints.only("xxxs")]: {
                        rowGap: 3,
                    },
                    [theme.breakpoints.only("xxs")]: {
                        rowGap: 10,
                    },
                    [theme.breakpoints.only("xs")]: {
                        rowGap: 4,
                    },
                    [theme.breakpoints.only("sm")]: {
                        rowGap: 4,
                    },
                    // rest
                    [theme.breakpoints.up("md")]: {
                        rowGap: 4,
                    },
                })}
            >
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontFamily: "'Garamond-Light-Cond'",
                            color: "secondary.main",
                            fontWeight: "500",
                            lineHeight: 1.0,
                        }}
                    >
                        Answers
                        <wbr />
                        &nbsp;at&nbsp;<i>scale</i>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "secondary.main",
                            maxWidth: "100%",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                        }}
                    >
                        AnswerGrid is an AI-powered web research tool realised as a spreadsheet.
                        <br />
                        For our first research use case, we&apos;re helping startups with high-quality sales
                        qualification.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignSelf: "center", alignItems: "center", gap: 2 }}>
                    <Button
                        id="try-it-button"
                        data-ph-capture-attribute-button="try-it-button"
                        variant="contained"
                        color="secondary"
                        href="https://app.answergrid.ai/try-it"
                        target="_blank"
                        size="large"
                        sx={{
                            color: "white",
                            borderRadius: 50,
                            textTransform: "unset",
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: "medium" }} noWrap>
                            Try it out
                        </Typography>
                    </Button>
                    <Button
                        id="book-demo-button"
                        data-ph-capture-attribute-button="book-demo-button"
                        variant="outlined"
                        size="large"
                        href="https://calendly.com/noah-answergrid/15-min-meeting"
                        target="_blank"
                        sx={{
                            color: "secondary.main",
                            borderColor: "divider",
                            textTransform: "unset",
                            borderRadius: 8,
                            fontWeight: "medium",
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: "medium" }} noWrap>
                            Book a demo
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
