import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import "./App.css";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xxxs: true;
        xxs: true;
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

export const SECONDARY_MAIN = "#365934";

export const theme = createTheme({
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F8F8F7",
        },
        secondary: {
            main: "#365934",
            // main: alpha("#000", 0.5),
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    breakpoints: {
        values: {
            xxxs: 0,
            xxs: 400,
            xs: 480,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});
