import React from "react";
import { Box, Button, lighten, Typography, useTheme } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { SECONDARY_MAIN } from "./theme";
import { useCurrentBreakpoint } from "./useCurrentBreakpoint";
import { useLocation, useNavigate } from "react-router-dom";

export const AppContentWithHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const currentBreakpoint = useCurrentBreakpoint();

    const navigate = useNavigate();

    const handlePricingClick = () => {
        navigate("/pricing");
    };

    const currentPath = useLocation();

    const isPricingPage = React.useMemo(() => currentPath.pathname === "/pricing", [currentPath]);

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "100vh",
                backgroundColor: "#F4F2EF",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "stretch",
                minWidth: 320,
                overflowX: "hidden",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    flexGrow: 1,
                    overflowX: "hidden",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: 2,
                        pb: 1.5,
                        height: 64,
                        px: 4,
                        overflowX: "hidden",
                        overflowY: "hidden",
                    }}
                >
                    <Box
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer", overflowY: "hidden" }}
                        onClick={() => navigate("/")}
                    >
                        <CircleOutlinedIcon
                            sx={{
                                stroke: SECONDARY_MAIN,
                                strokeWidth: "2px",
                                fontSize: "32px",
                                mb: "8px",
                                mr: 1,
                                [theme.breakpoints.only("xxxs")]: {
                                    fontSize: "22px",
                                    mb: "4px",
                                    strokeWidth: "2px",
                                },
                                [theme.breakpoints.only("xxs")]: {
                                    fontSize: "22px",
                                    mb: "4px",
                                    strokeWidth: "2px",
                                },
                                [theme.breakpoints.up("xs")]: {
                                    fontSize: "32px",
                                },
                            }}
                        />
                        <Typography
                            variant={currentBreakpoint === "xxxs" || currentBreakpoint === "xxs" ? "h5" : "h4"}
                            sx={{
                                fontFamily: "'Garamond-Light-Cond'",
                                color: "secondary.main",
                                mr: 0.5,
                                fontSize: "30px",
                            }}
                        >
                            AnswerGrid
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Button
                            id="pricing-button"
                            data-ph-capture-attribute-button="pricing-button"
                            sx={{
                                textTransform: "none",
                                bgcolor: isPricingPage ? lighten(theme.palette.secondary.main, 0.45) : "transparent",
                                color: isPricingPage ? "white" : "secondary.main",
                                "&:hover": {
                                    bgcolor: isPricingPage
                                        ? lighten(theme.palette.secondary.main, 0.45)
                                        : "transparent",
                                },
                            }}
                            variant={isPricingPage ? "contained" : "outlined"}
                            onClick={handlePricingClick}
                            size="small"
                        >
                            <Typography variant="body1">Pricing</Typography>
                        </Button>
                    </Box>
                </Box>
                {children}
            </Box>
        </Box>
    );
};
