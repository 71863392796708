import * as React from "react";
import { Box, Typography } from "@mui/material";
import { AppContentWithHeader } from "./AppContentWithHeader";
import { useCurrentBreakpoint } from "./useCurrentBreakpoint";
import { PlanCardGrid } from "./PlanCardGrid";

export const Pricing: React.FC = () => {
    const onContactUsClick = React.useCallback(() => {
        window.location.href = "mailto:founders@answergrid.ai";
    }, []);

    const handleGetStartedWithFreeClick = React.useCallback(() => {
        window.location.href = "https://app.answergrid.ai/signup";
    }, []);

    const handleGetStartedWithBasicClick = React.useCallback(() => {
        window.location.href = "https://app.answergrid.ai/signup?plan=basic";
    }, []);

    const handleGetStartedWithProClick = React.useCallback(() => {
        window.location.href = "https://app.answergrid.ai/signup?plan=pro";
    }, []);

    const [containerDimensions, setContainerDimensions] = React.useState({ width: 0, height: 0 });
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerDimensions({
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const cardsPerRow = containerDimensions.width > 700 ? 4 : 1;

    const breakpoint = useCurrentBreakpoint();

    return (
        <AppContentWithHeader>
            <Box
                ref={containerRef}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    py: 6,
                    bgcolor: "white",
                    mx: 2,
                    borderRadius: 2,
                    flexGrow: 1,
                    mb: 3,
                    overflowX: "hidden",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: "'Garamond-Light-Cond'",
                        color: "secondary.main",
                        fontWeight: "500",
                        textAlign: "center",
                    }}
                >
                    Pricing
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, textAlign: "center", px: 4 }}>
                    Use AnswerGrid for your web research workflows.
                    <br />
                    Unlock more query and export limits for you and your team.
                </Typography>
                <Box
                    sx={{
                        overflowX: "auto",
                        minWidth: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        px: breakpoint === "xxs" || breakpoint === "xxxs" ? 0 : 2,
                    }}
                >
                    <PlanCardGrid
                        cardsPerRow={cardsPerRow}
                        onGetStartedWithFreeClick={handleGetStartedWithFreeClick}
                        onGetStartedWithBasicClick={handleGetStartedWithBasicClick}
                        onGetStartedWithProClick={handleGetStartedWithProClick}
                        onContactUsClick={onContactUsClick}
                    />
                </Box>
            </Box>
        </AppContentWithHeader>
    );
};
